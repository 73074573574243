<template>
  <a-row class="demo-detail">
    <a-breadcrumb class="a-bread-sty" separator=">">
      <a-breadcrumb-item v-for="item in breadList" :key="item.name">
        <router-link :to="{ path: item.path === '' ? '/' : item.path }">{{
          item.meta.title
        }}</router-link>
      </a-breadcrumb-item>
    </a-breadcrumb>
    <h2 align="center">工厂级室内CCR集中操作监控与管理</h2>

    <div class="top">
      <p>
        该项目具备品评数据看板、供应商管理、KPI计算分析水瀑图、批次报表、与其他系统对接等功能。该项目为更进一点推动工厂数据字化转型，提升PI数字化管理。
      </p>
      <img src="../../assets/image/adminName.png" />
    </div>
    <div class="proj-role">
      <p class="proj-one">项目作用</p>
      <p class="proj-t">
        一站式实现生产操作，维护，质检等生产管理工作，实现减员增效，现场预置工具，资料。耗材，人员游动工作，扁平化、多技能、协同生产。
      </p>
    </div>

    <h2 align="center">酿酒行业控制系统解决方案</h2>
    <p class="to-p" align="center">
      精通国际主流控制系统平台，为酿造行业定制化开发工艺模版，提高生产控制系统稳定性和控制精度。
    </p>

    <div class="swiper">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in planList" :key="item.id" @mouseover="switchItem(item)">
          <img :src="item.img" />
        </div>
      </div>
      <!-- <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div> -->
    </div>

    <!-- <h2 align="center">项目亮点</h2>
    <div class="bottom-box">
      <img class="box-img" src="../../assets/image/32650.png">
      <ul class="list">
        <li>集中管控各生产线生产
          流程，减少了中间环节 </li>
        <li>报错机制，生产过程中
          的问题会有及时响应，提高生产效率。</li>
        <li>集中管控各生产线生
          产流程，减少了中间环节 </li>
        <li>报错机制，生产过程中的
          问题会有及时响应，提高生产效率。</li>
      </ul>
    </div> -->
  </a-row>
</template>

<script>
import Swiper from "../../../node_modules/swiper/swiper-bundle.js";
export default {
  mounted() {
    this.getBreadcrumb();
    var mySwiper = new Swiper(".swiper", {
      direction: "horizontal", //
      loop: true,
      breakpoints: {
        320: {
          //当屏幕宽度大于等于320
          slidesPerView: 2,
          spaceBetween: 10,
        },
        768: {
          //当屏幕宽度大于等于768
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1280: {
          //当屏幕宽度大于等于1280
          slidesPerView: 4,
          spaceBetween: 30,
        },
      },
      autoplay: {
        delay: 1000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      disableOnInteraction: false,
    });
  },
  data() {
    return {
      planList: [
        {
          id: 1,
          img: require("../../assets/image/CCR-2.png"),
        },
        {
          id: 2,
          img: require("../../assets/image/CCR-3.png"),
        },
        {
          id: 3,
          img: require("../../assets/image/CCR-4.png"),
        },
        {
          id: 4,
          img: require("../../assets/image/CCR-5.png"),
        },
      ],

      breadName: "",
      breadList: [],
    };
  },
  methods: {
    getBreadcrumb() {
      this.breadList = [];
      this.breadName = this.$route.name;
      this.$route.matched.forEach((ele, index, arr) => {
        this.breadList.push(ele);
      });
    },
  },
};
</script>
<style lang="less">
.demo-detail {
  margin: 0 auto;

  h2 {
    margin-top: 120px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
  }

  .a-bread-sty {
    width: 1440px;
    margin: 40px auto;
    font-size: 20px;
  }

  .top {
    width: 1440px;
    margin: 0 auto;
    margin-top: 140px;
    padding: 51px;
    background: linear-gradient(#f9fafe, #e7eeff);
    border-radius: 20px;
    box-shadow: 0 10px 20px #cccccc;
    display: flex;
    align-items: center;

    img {
      width: 200px;
      height: 300px;
    }
  }

  .to-p {
    margin-bottom: 40px;
  }

  .swiper {
    width: 1440px;
    border-radius: 20px;

    .swiper-slide {
      img {
        width: 100%;
        height: 200px;
      }
    }
  }

  .proj-role {
    margin: 0 auto;
    margin-top: 150px;
    width: 1440px;
    border-radius: 25px;
    padding: 50px;
    color: #ffffff;
    // background: linear-gradient(to right, #ff6600, #ff9354);
    background: url("../../assets/image/32647.png") no-repeat;
    background-size: cover;
    box-shadow: 0 10px 20px #cccccc;

    .proj-one {
      font-size: 40px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    .proj-t {
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 400;
    }
  }

  .bottom-box {
    width: 100%;
    background: url("../../assets/image/32651.png");
    text-align: center;

    .box-img {
      width: 1440px;
      height: 529px;
    }

    .list {
      margin: 0 auto;
      width: 1440px;

      display: flex;
      justify-content: space-between;
      padding-bottom: 50px;

      li {
        width: 20%;

        &:nth-child(1),
        &:nth-child(4) {
          margin-top: -220px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          margin-top: -50px;
        }
      }
    }
  }
}

@media all and (max-width: 700px) {
  .demo-detail {
    margin: 0 auto;
    padding: 0 200px;
    h2 {
      margin-top: 120px;
      font-size: 80px;
      font-family: Source Han Sans CN;
      font-weight: bold;
    }

    .a-bread-sty {
      width: 1440px;
      margin: 40px auto;
      font-size: 40px;
    }

    .top {
      width: 1440px;
      margin: 0 auto;
      margin-top: 140px;
      padding: 51px;
      background: linear-gradient(#f9fafe, #e7eeff);
      border-radius: 20px;
      box-shadow: 0 10px 20px #cccccc;
      display: flex;
      align-items: center;

      img {
        width: 200px;
        height: 300px;
      }
      p {
        font-size: 48px;
      }
    }

    .to-p {
      margin-bottom: 40px;
      font-size: 48px;
    }

    .swiper {
      width: 1440px;
      border-radius: 20px;

      .swiper-slide {
        img {
          width: 100%;
          height: 200px;
        }
      }
    }

    .proj-role {
      margin: 0 auto;
      margin-top: 150px;
      width: 1440px;
      border-radius: 25px;
      padding: 50px;
      color: #ffffff;
      // background: linear-gradient(to right, #ff6600, #ff9354);
      background: url("../../assets/image/32647.png") no-repeat;
      background-size: cover;
      box-shadow: 0 10px 20px #cccccc;

      .proj-one {
        font-size: 80px;
        font-family: Source Han Sans CN;
        font-weight: bold;
      }

      .proj-t {
        font-size: 48px;
        font-family: Source Han Sans CN;
        font-weight: 400;
      }
    }

    .bottom-box {
      width: 100%;
      background: url("../../assets/image/32651.png");
      text-align: center;

      .box-img {
        width: 1440px;
        height: 529px;
      }

      .list {
        margin: 0 auto;
        width: 1440px;

        display: flex;
        justify-content: space-between;
        padding-bottom: 50px;

        li {
          width: 20%;

          &:nth-child(1),
          &:nth-child(4) {
            margin-top: -220px;
          }

          &:nth-child(2),
          &:nth-child(3) {
            margin-top: -50px;
          }
        }
      }
    }
  }
}
</style>
